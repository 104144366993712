import React from 'react'
import Routes from './routes'
import Interceptors from './utils/interceptor'
import store from './store'
import { StoreProvider } from 'easy-peasy'
import './assets/styles/app.less'
import Xendit from 'xendit-js-node'

Interceptors.setupInterceptors(store)
Xendit.setPublishableKey(process.env.REACT_APP_XENDIT_PK)

const App = () => (
  <StoreProvider store={store}>
    <Routes />
  </StoreProvider>
)

export default App
